/* eslint-disable */
export default {
  user: {},
  update: false,
  cities: [],
  categories: [],
  passwordError: "",
  phoneError: "",
  codeError: "",
  phoneToken: false,
  nameError: "",
  wpupdate: false,
};
