import React from "react";

function CloseIcon({ onClick }) {
  return (
    <div className="close-icon" onClick={onClick}>
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24.1012 22.7748C24.1884 22.8618 24.2575 22.9653 24.3046 23.079C24.3517 23.1928 24.376 23.3148 24.376 23.4379C24.376 23.5612 24.3517 23.6832 24.3046 23.797C24.2575 23.9107 24.1884 24.0142 24.1012 24.1012C24.0142 24.1884 23.9107 24.2575 23.797 24.3046C23.6832 24.3517 23.5612 24.376 23.4379 24.376C23.3148 24.376 23.1928 24.3517 23.079 24.3046C22.9653 24.2575 22.8618 24.1884 22.7748 24.1012L15.0004 16.3258L7.22631 24.1012C7.05039 24.2772 6.8118 24.376 6.56302 24.376C6.31425 24.376 6.07566 24.2772 5.89974 24.1012C5.72382 23.9254 5.625 23.6868 5.625 23.4379C5.625 23.1892 5.72382 22.9506 5.89974 22.7748L13.6751 15.0004L5.89974 7.22631C5.72382 7.05039 5.625 6.8118 5.625 6.56302C5.625 6.31425 5.72382 6.07566 5.89974 5.89974C6.07566 5.72382 6.31425 5.625 6.56302 5.625C6.8118 5.625 7.05039 5.72382 7.22631 5.89974L15.0004 13.6751L22.7748 5.89974C22.9506 5.72382 23.1892 5.625 23.4379 5.625C23.6868 5.625 23.9254 5.72382 24.1012 5.89974C24.2772 6.07566 24.376 6.31425 24.376 6.56302C24.376 6.8118 24.2772 7.05039 24.1012 7.22631L16.3258 15.0004L24.1012 22.7748Z"
          fill="black"
        />
      </svg>
    </div>
  );
}

export default CloseIcon;
