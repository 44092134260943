import { RublIcon } from "../svg";
import RichTextEditor from "../editor";
import { useEffect, useState } from "react";
import "./style.css";
import { EditIcon } from "../svg";

export const EditProductFields = ({
  details,
  setDetails,
  errors,
  setErrors,
  categories,
  selectedCategory,
  setSelectedCategory,
  selectedSubcategory,
  setSelectedSubcategory,
  setCategoryHasSubcategory,
  description,
  setDescription,
}) => {
  function handleCategoryChange(event) {
    setSelectedCategory([]);
    setSelectedSubcategory([]);
    const category = categories.filter(
      (elm) => +elm.id === +event.target.value
    )[0];
    setSelectedCategory(category);
    if (category?.childrens?.length > 0) {
      setCategoryHasSubcategory(true);
    } else {
      setCategoryHasSubcategory(false);
      setErrors({ ...errors, subcategory: "" });
    }
  }
  function handleSubcategoryChange(event) {
    setSelectedSubcategory(
      selectedCategory?.childrens?.filter(
        (elm) => +elm.id === +event.target.value
      )[0]
    );
  }

  const [showFacades, setShowFacades] = useState(false);
  const [showFrame, setShowFrame] = useState(false);
  const [showTabletop, setShowTabletop] = useState(false);
  const [edit, setEdit] = useState({
    description: false,
  });
  const [showLength, setShowLength] = useState(false);
  const [showHeight, setShowHeight] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showMaterial, setShowMaterial] = useState(true);

  const facadeIds = new Set([
    28, 30, 31, 36, 42, 43, 45, 46, 50, 51, 57, 63, 65, 66, 58, 40, 41,
  ]);
  const frameIds = new Set([
    28, 30, 31, 36, 42, 43, 45, 46, 50, 51, 58, 63, 65, 66, 94, 95, 96, 97, 98,
    37, 40, 41, 57,
  ]);
  const tabletopIds = new Set([28, 30, 40, 50]);
  const lengthIds = new Set([
    28, 30, 31, 36, 42, 43, 94, 45, 46, 50, 51, 95, 57, 58, 96, 63, 97, 65, 66,
    98, 37, 94, 40, 41,
  ]);
  const heightIds = new Set([
    31, 36, 37, 41, 43, 94, 50, 51, 95, 57, 58, 96, 63, 66, 98, 97,
  ]);
  const profileIds = new Set([37, 94, 95, 96, 97, 98]);
  const materialIds = new Set([
    32, 33, 34, 35, 44, 48, 52, 53, 54, 55, 56, 59, 60, 61, 62, 64, 80, 81, 82,
    83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 38, 67, 68, 69, 71, 72, 73, 74, 39,
    29, 47, 48, 70, 49, 75, 76, 77, 78, 79, 99, 100, 101,
  ]);
  const categoryIds = new Set([10, 19, 21, 25, 26, 11, 12, 13, 20, 22]);

  useEffect(() => {
    if (selectedSubcategory || selectedCategory) {
      const subcategoryId = selectedSubcategory?.id;
      const parentCategoryId = selectedSubcategory?.parent_id;
      const categoryId = selectedCategory?.id;

      const showFacades = facadeIds.has(subcategoryId);
      const showFrame = frameIds.has(subcategoryId);
      const showTabletop = tabletopIds.has(subcategoryId);
      const showLength = lengthIds.has(subcategoryId);
      const showHeight = heightIds.has(subcategoryId);
      const showProfile = profileIds.has(subcategoryId);

      let showMaterial = true;

      if (showFacades || showFrame || showTabletop || showProfile) {
        showMaterial = false;
        setShowMaterial(false);
      }

      setShowFacades(showFacades);
      setShowFrame(showFrame);
      setShowTabletop(showTabletop);
      setShowLength(showLength);
      setShowHeight(showHeight);
      setShowProfile(showProfile);
      setShowMaterial(showMaterial);

      if (!showFacades) {
        setDetails((prevDetails) => ({ ...prevDetails, facades: "" }));
      }
      if (!showFrame) {
        setDetails((prevDetails) => ({ ...prevDetails, frame: "" }));
      }
      if (!showTabletop) {
        setDetails((prevDetails) => ({ ...prevDetails, tabletop: "" }));
      }
      if (!showLength) {
        setDetails((prevDetails) => ({ ...prevDetails, length: "" }));
      }
      if (!showHeight) {
        setDetails((prevDetails) => ({ ...prevDetails, height: "" }));
      }
      if (!showProfile) {
        setDetails((prevDetails) => ({ ...prevDetails, profile: "" }));
      }
      if (!showMaterial) {
        setDetails((prevDetails) => ({ ...prevDetails, material: "" }));
      }
    }
  }, [selectedSubcategory, selectedCategory]);
  return (
    <div className="myProfileBlockk">
      <div className="addProductEachBlock">
        <div className="eachProfileField">
          <div className="profileFieldName">
            <span>Имя продукции</span>
          </div>
          <input
            value={details.name}
            onChange={(e) => setDetails({ ...details, name: e.target.value })}
            placeholder="Кухня ЛРАЙ 145 МДФ ПВХ Сатин Бежевый/СИСТЕМА "
            style={errors.name ? { border: "1px solid red" } : {}}
            maxLength={42}
          />
        </div>
        {showFrame && (
          <div className="eachProfileField">
            <div className="profileFieldName">
              <span>Корпус</span>
            </div>
            <input
              value={details.frame}
              onChange={(e) =>
                setDetails({ ...details, frame: e.target.value })
              }
              placeholder="Корпус"
            />
          </div>
        )}
        {showLength && (
          <div className="eachProfileField">
            <div className="profileFieldName">
              <span>Длина</span>
            </div>
            <input
              type="number"
              value={details.length}
              onChange={(e) =>
                setDetails({ ...details, length: e.target.value })
              }
              placeholder="3,5 метра"
            />
          </div>
        )}
        {showHeight && (
          <div className="eachProfileField">
            <div className="profileFieldName">
              <span>Высота</span>
            </div>
            <input
              type="number"
              value={details.height}
              onChange={(e) =>
                setDetails({ ...details, height: e.target.value })
              }
              placeholder="1,8 метра"
            />
          </div>
        )}
        {/* <div className="eachProfileField">
          <div className="profileFieldName">
            <span>Доп. информация</span>
          </div>
          <RichTextEditor
            userDetails={description != "undefined" ? description : ""}
            setUserDetails={setDescription}
          />
        </div> */}
        <div className="eachProfileField">
          {" "}
          {/* Доп. информация */}
          <div className="profileFieldName">
            <span>Доп. информация</span>
            <div
              className="cursor"
              onClick={() => setEdit({ ...edit, description: true })}
            >
              <EditIcon />
            </div>
          </div>
          {edit.description ? (
            <RichTextEditor
              userDetails={description != "undefined" ? description : ""}
              setUserDetails={setDescription}
            />
          ) : (
            // <TextEditor userDetails={userDetails} />
            <div
              className="aboutBlock"
              dangerouslySetInnerHTML={{
                __html: description ? description : "",
              }}
            />
          )}
        </div>
        <div className="eachProfileField">
          <div className="profileFieldName">
            <span>Цена</span>
          </div>
          <div className="addProductPrice">
            <input
              value={details.price}
              type="number"
              onChange={(e) =>
                setDetails({ ...details, price: e.target.value })
              }
              placeholder="Цена"
            />
            <RublIcon />
          </div>
        </div>
      </div>
      <div className="addProductEachBlock">
        {showMaterial && (
          <div className="eachProfileField">
            <div className="profileFieldName">
              <span>Материал</span>
            </div>
            <input
              value={details.material}
              onChange={(e) =>
                setDetails({ ...details, material: e.target.value })
              }
              placeholder="Материал"
              style={errors.material ? { border: "1px solid red" } : {}}
              maxLength={52}
            />
          </div>
        )}

        <div className="eachProfileField">
          <div className="profileFieldName">
            <span>Категория</span>
          </div>
          <select
            value={selectedCategory?.id}
            onChange={handleCategoryChange}
            style={errors.category ? { border: "1px solid red" } : {}}
          >
            <option value=""></option>
            {categories?.map((category) => (
              <option key={category.id} value={category?.id}>
                {category?.name}
              </option>
            ))}
          </select>
        </div>
        {selectedCategory?.childrens?.length > 0 && (
          <div className="eachProfileField">
            <div className="profileFieldName">
              <span>Подкатегория</span>
            </div>
            <select
              value={selectedSubcategory?.id}
              onChange={handleSubcategoryChange}
              style={errors.subcategory ? { border: "1px solid red" } : {}}
            >
              <option value=""></option>
              {selectedCategory?.childrens?.map((subcategory) => (
                <option key={subcategory?.id} value={subcategory?.id}>
                  {subcategory?.name}
                </option>
              ))}
            </select>
          </div>
        )}
        {showTabletop && (
          <div className="eachProfileField">
            <div className="profileFieldName">
              <span>Столешница</span>
            </div>
            <input
              value={details?.tabletop}
              onChange={(e) =>
                setDetails({ ...details, tabletop: e.target.value })
              }
              placeholder="Столешница"
            />
          </div>
        )}
        {showFacades && (
          <div className="eachProfileField">
            <div className="profileFieldName">
              <span>Фасады</span>
            </div>
            <input
              value={details?.facades}
              onChange={(e) =>
                setDetails({ ...details, facades: e.target.value })
              }
              placeholder="Фасады"
            />
          </div>
        )}
        {showProfile && (
          <div className="eachProfileField">
            <div className="profileFieldName">
              <span>Профиль</span>
            </div>
            <input
              value={details?.profile}
              onChange={(e) =>
                setDetails({ ...details, profile: e.target.value })
              }
              placeholder="Профиль"
            />
          </div>
        )}
      </div>
    </div>
  );
};
